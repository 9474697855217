.methodoly-info-image {
  position: relative;
  margin-top: 4em;
  padding-bottom: 3px;
}

.dots-wrapper {
  width: 100%;
}

.methodoly-info-image .dot {
  position: absolute;
  width: 14px;
  height: 14px;
  border: 2px solid white;
  background-color: black;
  border-radius: 50%;
  z-index: 3;
}

.methodoly-info-image .dot:hover {
  background-color: white;
  cursor: pointer;
}

.methodoly-info-image .dot.selected {
  background-color: white;
}

.methodoly-info-image .square {
  position: absolute;
  background-color: #000000c4;
  transition: 0.5s all ease;
  right: 100%;
}
/*
.methodoly-info-image .square.selected {
  width: 0% !important;
}
*/

.methodology-info-text {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  height: 100%;
  text-align: center;
}

.methodology-info-text-inner {
  width: 70%;
  height: 38%;
}

.methodology-info-text h2 {
  font: bold italic 1.8em "Thanos";
}

.methodology-info-text p {
  font: 400 1.2em "Thanos";
}
