#main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
#hide-box {
  width: 100%;
  height: 100%;
  z-index: 5;
  display: none;
  position: absolute;
  background-color: transparent;
}

#hide-box.display {
  display: block;
}
