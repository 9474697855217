.contact-form {
  color: white;
  display: block;
  justify-content: center;
  align-items: end;
}

.contact-form .contact-title {
  font: bold italic 1.6rem "Tinos";
  margin: 0 0 0.6em 0;
}

.contact-form .contact-info {
  font: 100 1em "Outfit";
  margin: 0 0 1em 0;
}

.contact-form.inverted {
  color: black;
}

.contact-form .contact-block {
  display: flex;
  margin-top: 1em;
}

.contact-form input {
  flex: 1;
  margin-right: 1em;
}

.contact-form input:last-child {
  margin-right: 0;
}

.contact-form input {
  padding: 0.4em 0.8em;
  font: 100 1.1rem "Outfit";

  border: 1px solid white;
  background: black;
  color: white;
}

.contact-form.inverted input {
  border: 1px solid black;
  background: white;
  color: black;
}

/* Only button - footer */
.contact-form-only-button {
  height: 100%;
  display: flex;
  gap: 2rem;
  align-items: center;
}

.contact-form-only-button .contact-title {
  margin-bottom: 0.5rem;
}

.contact-form-only-button .contact-info {
  margin-bottom: 0rem;
  font-size: 1rem;
}

.contact-form-only-button .form-button {
  font-size: 1rem;
}
.contact-form-only-button .only-no-mobile {
  display: block;
}

.contact-form-only-button .only-mobile {
  display: none;
}

.contact-form-confirmation {
  visibility: hidden;
  transition: all 0.5s;
}
.contact-form-confirmation.submitted {
  visibility: visible;
}

.contact-form-confirmation p {
  font: 100 1.1rem "Outfit";
  color: #00b000;
  transition: all 0.5s;
}

@media (max-width: 1049px) {
  .contact-form-only-button {
    display: block;
  }
  .contact-form-only-button .contact-info {
    margin-bottom: 0.5rem;
  }
}

@media (max-width: 900px) {
  #contact-form-wrapper {
    display: block;
  }

  .contact-form .contact-block {
    margin: 0;
  }
  .contact-form {
    text-align: center;
  }
  .contact-form .button {
    margin-bottom: 1rem;
  }

  .contact-form .contact-block {
    flex-direction: column;
  }

  .contact-form .contact-block input {
    margin-bottom: 0.6rem;
    margin-right: 0;
  }

  .contact-form-only-button .only-no-mobile {
    display: none;
  }

  .contact-form-only-button .only-mobile {
    display: block;
  }
}
