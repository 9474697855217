#credentials-filter {
  margin-top: 1.2em;
  margin-bottom: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

#credentials-filter .section-title {
  margin-right: 3em;
}

#credentials-filter .button {
  margin-right: 0.5em;
}

.credentials {
  margin-top: 12em;
  scroll-behavior: smooth;
}

.filter-buttons {
  display: flex;
}

.filter-buttons-inner {
  display: flex;
}

.credentials-fixed {
  padding-top: 3em;
  position: fixed;
  width: 100%;
  z-index: 10;
  background: black;
  transition: all 0.3s ease;
}

.scrolled .credentials-fixed {
  padding-top: 0;
}

#credentials-section-title-wrapper {
  display: block;
  transition: all 0.3s ease;
}

.scrolled #credentials-section-title-wrapper {
  display: none;
}

.scrolled .credentials {
  margin-top: 4em;
  scroll-behavior: smooth;
}

.credentials-fixed.hide-fixed {
  /* display: none;*/
  position: absolute;
}

#credentials-filter-mobile {
  display: none;
}

@media (max-width: 1000px) {
  #credentials-filter-mobile {
    display: block;
    margin-bottom: 1em;
  }

  .credentials {
    margin-top: 10em;
  }

  .credentials-fixed {
    padding-top: 2em;
  }

  #credentials-filter {
    display: none;
    flex-direction: column;
    align-items: center;
  }
  #credentials-filter .section-title {
    margin-right: 0;
    margin-bottom: 1rem;
  }
  .filter-buttons {
    margin-top: 1rem;
    flex-direction: row;
    align-items: center;
  }

  .filter-buttons-gap {
    width: 16px;
    display: inline-block;
  }
}
