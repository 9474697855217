.select-button-container {
  flex: 1;
}

.select-button-options {
  max-height: 20em;
  overflow-y: auto;
  padding: 1em 3em 1em 1em;
  display: none;
  position: absolute;
  background-color: #0c0c0c;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgb(255 255 255 / 30%);
  z-index: 10;
}

.select-button-options .select-option {
  padding: 1em 0.5em;
  cursor: pointer;
  color: #fff;
  font: 100 1rem "Outfit";
}

.select-button-options.display {
  display: block;
}
