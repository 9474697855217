.section-title-options {
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-option-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-option {
  cursor: pointer;
}

.section-option.selected {
  cursor: pointer;
}

.section-option:after {
  height: 3px;
  display: block;
  width: 90%;
  text-align: center;
  margin: 2px auto 0 auto;
  background: black;
  content: "";
  transition: all 0.3s;
}

.section-option.selected:after {
  background: white;
}
/* 
  transform: translate(-10rem, 0); */

.section-horizontal-divider {
  width: 1px;
  height: 2rem;
  margin: 0 1.2rem;
  background-color: white;
}

.section-title-options .section-option {
  font: 300 1.7rem "Outfit";
}

@media (max-width: 1000px) {
  .section-title-options .section-option {
    font: 300 1rem "Outfit";
  }
}

@media (max-width: 900px) {
  .section-title-options {
    margin-top: 0.5rem;
  }
  .section-horizontal-divider {
    height: 1.4rem;
  }
}
