.button {
  border: 2px solid #fff;
  padding: 0.6rem 1.3rem;
  color: #fff;
  display: inline-block;
  transition: all 0.3s ease;
  font: 100 1rem "Outfit";
  text-transform: uppercase;
  cursor: pointer;
}

.button:hover {
  background-color: #fff;
  color: #000;
}

.small {
  padding: 0.3rem 0.6rem;
  font: 900 0.8rem "Outfit";
}

.button.fill-background {
  background-color: #fff;
  color: #000;
}

.inverted {
  background-color: #fff;
  color: #000000;
}

.button.fill-background.inverted {
  background-color: black;
  color: white;
}

.fixed {
  width: 13.05rem;
}

.trailing.button {
  display: flex;
}

.trailing span {
  flex-grow: 1;
}

.trailing img {
  width: 1.3rem;
}

.trailing.button:hover img {
  filter: invert(1);
  transition: all 0.3s ease;
}

@media (max-width: 900px) {
  .fixed {
    width: 7.5rem;
    text-align: center;
  }
  .button {
    font-size: 1rem;
    padding: 0.4rem 0.4rem;
  }
}
