#color-selector-wrapper {
  margin-top: 80px;
}

/*** Service Selector - start ***/
#service-selector {
  position: relative;
  height: 80px;
}

.color-line {
  height: 2px;
  position: absolute;
  width: 100%;
  bottom: 14px;

  background: linear-gradient(
    to right,
    #003c5d 0%,
    #1471e3 13%,
    #3aa080 26%,
    #abd32d 39%,
    #fbdc39 52%,
    #fc561e 65%,
    #d12435 78%,
    #95698b 100%
  );
}

.service-options {
  position: absolute;
  bottom: 0px;
  width: 120px;
  text-align: center;
}

.service-options.left {
  left: 0;
}

.service-options.middle {
  padding: 0;
  margin: auto;
  left: 50%;
  transform: translateX(-50%);
}

.service-options.right {
  right: 0;
}

.service-options p {
  font-family: "Gabarito";
  font-weight: 500;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.service-options p {
  text-shadow: #fc0 1px 0 10px;
}

.service-options img {
  width: 45px;
  transition: all 0.3s ease;
}

.service-options img {
  filter: drop-shadow(1px 1px 7px rgba(255, 255, 255, 0.8));
}

/*** Service Selector - end ***/

@media (max-width: 600px) {
  .service-options.block {
    padding: 0;
  }
  .service-options p {
    width: 100px;
    font-size: 1rem;
    text-align: center;
    display: inline-block;
  }

  .color-selector-wrapper {
    margin-top: 2em;
  }
}

@media (min-width: 1200px) {
  .service-options.left {
    left: auto;
    right: calc(50% + 400px);
  }

  .service-options.right {
    right: auto;
    left: calc(50% + 400px);
  }
}

@media (min-width: 2000px) {
  .service-options.left {
    left: auto;
    right: calc(50% + 550px);
  }
  .service-options.right {
    right: auto;
    left: calc(50% + 550px);
  }
}
