.language-selector {
  justify-content: center;
  display: flex;
  justify-items: center;
  flex-direction: row;
}
.language-selector img {
  width: 30px;
  height: 20px;
  margin-left: 8px;
}

.language-selector span {
  text-transform: uppercase;
  color: #ffffff;
  text-decoration: none;
  font: 900 0.79rem "Gabarito";

  padding: 0;
  margin: 0;
  padding-top: 2px;
  padding-left: 10px;
}

.language-selector:hover {
  cursor: pointer;
}

.language-selector:hover span {
  cursor: pointer;
  text-decoration: underline;
}
