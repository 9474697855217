#about-us-page {
  margin-bottom: 10em;
}

#about-us-page .section-title {
  margin-bottom: 4rem;
}

#about-us-video-block {
  margin-top: 4em;
  position: relative;
}

#about-us-video-block .about-bg {
  width: 100%;
}

#about-us-video-block .text {
  position: absolute;
  right: 0;
  top: 0;
  text-align: center;
  padding-left: 0rem;
  padding-right: 3.5rem;
}

.about-title {
  font: 100 1.4rem "Outfit";
  text-transform: uppercase;
  margin: 0;
}
.about-content {
  font: bold italic 1.4rem "Tinos";
  margin: 0.6em 0 0 0;
}

.about-logo {
  margin-top: 2em;
  width: 5em;
}

@media (max-width: 988px) {
  #about-us-video-block .text {
    position: relative;
  }
}

@media (min-width: 1500px) {
  #about-us-video-block .text {
    top: 50px;
  }
}

@media (min-width: 1800px) {
  #about-us-video-block .text {
    top: 150px;
    right: 2%;
  }
}

@media (min-width: 2400px) {
  #about-us-video-block .text {
    top: 10%;
    right: 5%;
  }
}
