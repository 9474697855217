#header-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem 1rem 2rem;
  background-color: #000000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

header .logo img {
  height: 2rem;
}

header h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #ffffff;
}
header h2 a {
  color: #ffffff;
  text-decoration: none;
}

#header nav {
  display: flex;
  align-items: center;
  gap: 1rem;
}

#header nav a {
  text-transform: uppercase;
  color: #ffffff;
  text-decoration: none;
  font: 900 0.79rem "Gabarito";
}

#header-gap {
  margin-top: 5em;
}

#header nav a:hover {
  cursor: pointer;
  text-decoration: underline;
}

/* Mobile nav */
#header .mobile-nav {
  display: block;
}

#nav-button-options {
  max-height: 20em;
  overflow-y: auto;
  padding: 1em 3em 1em 1em;
  display: none;
  position: absolute;
  background-color: #0c0c0c;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgb(255 255 255 / 30%);
  z-index: 20;
  right: 0;
}

#nav-button-options .nav-option {
  padding: 1em 0.5em;
  cursor: pointer;
  color: #fff;
}

#nav-button-options.display {
  display: block;
}

@media (max-width: 1000px) {
  #nav-button-options {
    box-shadow: 0px 1px 8px 0px rgb(255 255 255 / 30%);
  }
  header {
    padding: 0.8rem 1rem;
  }
  header .logo img {
    height: 1.5rem;
  }
  #header-gap {
    margin-top: 4.4rem;
  }
  #header nav.full-nav {
    display: none;
  }
  #header .mobile-nav {
    display: block;
  }
  #header .mobile-nav img {
    height: 31px;
    filter: brightness(0) invert(1);
  }
}

@media (max-width: 1250px) {
  #header nav {
    gap: 0.6rem;
  }
  header .logo img {
    height: 1.5rem;
  }
  #header-gap {
    margin-top: 4.1rem;
  }
}

@media (min-width: 2000px) {
  header {
    padding: 1.5rem 5rem 1rem 5rem;
  }
}
