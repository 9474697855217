.credential-media {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  align-items: center;
  justify-content: center;
  transition: all 2.5s ease;
}

.credential-media.closed {
  position: inherit;
  display: none;
}

.close-credential {
  background: #000000e8;
  width: 100%;
  height: 100%;
  position: absolute;
}

.credential-media img {
  max-width: 80%;
  max-height: 80%;
  transition: all 2.5s ease;
}

.credential-media .player-wrapper {
  height: 80%;
  width: 80%;
  z-index: 20;
}

.credential-media .player-wrapper.internal {
  height: auto;
}

.credential-media .video-loading {
  background: #000000e8;
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.media-button {
  position: fixed;
  width: 50px;
  height: 50px;
  opacity: 0.3;
  transition: all 0.3s ease;
}

.media-button:hover {
  opacity: 0.7;
  cursor: pointer;
}

.media-prev {
  left: 3%;
  top: 50%;
  transform: translateY(-50%);
}

.media-next {
  right: 3%;
  top: 50%;
  transform: translateY(-50%);
}

.media-prev img {
  transform: rotate(-90deg);
}
.media-next img {
  transform: rotate(90deg);
}

.close-icon-wrapper {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  padding: 0.1em;
  font-size: 1em;
  background: #80808033;
  height: 2em;
  border-radius: 3em;
  width: 2em;
  display: flex;
  justify-content: center;
  align-self: center;
  line-height: 0;
  align-items: center;
  justify-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.close-icon-wrapper:hover {
  background: #80808096;
}

.loading {
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 4px solid rgb(98, 98, 98);
  border-top-color: white;
  animation: loading 1s linear infinite;
}

@keyframes loading {
  to {
    transform: rotate(360deg);
  }
}
