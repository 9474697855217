.credential-full-details {
  height: auto;
  margin-top: 3em;
}

.credential-full-details .main-content {
  height: 30em;
  background-color: rgb(3, 3, 70);
  width: 100%;
  position: relative;
}

.credential-full-details .main-content .main-image {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.credential-full-details .main-content .main-image:hover {
  cursor: pointer;
  opacity: 0.9;
  transition: all 0.3s ease-in-out;
}

.credential-full-details .main-content .play-video {
  position: absolute;
  cursor: pointer;
  width: 5%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.credential-details-info {
  margin-bottom: 3em;
}

.credential-details-info .credential-details-name {
  font: bold italic 1.5em "Tinos";
}

.credential-details-info .credential-details-description {
  font: 400 1em "Tinos";
  line-height: 1.2em;
}

.credential-full-details .horizontal-line {
  width: 100%;
  height: 1px;
  background-color: white;
}

.credential-full-details .collapse-button {
  width: 2em;
  height: 2em;
  margin-left: 5em;
  margin-bottom: 5em;
  cursor: pointer;
}

.credential-full-details .collapse-button.floating {
  width: 2em;
  height: 2em;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
  transform: rotate(180deg);
}

.credential-details-moreinfo {
  margin: 2em auto;
  column-gap: 2em;
  display: flex;
}

.credential-details-moreinfo .moreinfo-block {
  flex: 1 1 0;
  flex-grow: 1;
  width: 0;
}

.credential-details-moreinfo .moreinfo-block-special {
  flex: 2 1 0;
  flex-grow: 2;
  width: 0;
  display: flex;
}

.credential-details-moreinfo .services {
  flex: 2 1 0;
  flex-grow: 2;
  width: 0;
}

.credential-details-moreinfo p {
  font: 400 1em "Tinos";
  line-height: 1.2em;
}

.credential-details-moreinfo .title {
  font: 900 1em "Outfit";
  line-height: 1.2em;
  text-transform: uppercase;
}

.credential-details-moreinfo img {
  width: 10vw;
  max-width: 200px;
  max-height: 50%;
  object-fit: contain;
}

.reference-container {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.credential-details-moreinfo .reference-block {
  margin: 0 0 0.5em 0;
  padding: 0.5em;
  border: 1px solid #fff;
  color: white;
  font: 400 1em "Tinos";
  line-height: 1.2em;
  width: 8em;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s;
}

.credential-details-moreinfo .reference-block:hover {
  background-color: #fff;
  color: #000;
  cursor: pointer;
}

/*** SLIDER ***/
.credential-details-moreinfo.slider-info {
  display: inline-block;
  margin: 0;
  margin-top: 3em;
}

.credential-full-details .slider-content {
  height: 18em;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  text-align: center;
}

.credential-full-details .slider-content.container {
  padding: 0;
}

.credential-full-details .slider-content-scrollable {
  position: absolute;
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

div.slider-content-child {
  width: 14em;
  height: 14em;
  position: relative;
  overflow: hidden;
}

.slider-content-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.credential-full-details .slider-content-scrollable::-webkit-scrollbar {
  display: none;
}

.credential-full-details .slider-content-scrollable p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  max-width: 98%; /* Para que el texto no toque los bordes */
  word-wrap: break-word; /* Rompe las palabras largas si es necesario */
  white-space: normal; /* Permite saltos de línea */
  margin: 0;
  text-transform: uppercase;
}

.slider-content-scrollable .image-container {
  margin-right: 1em;
}

.slider-content-scrollable:last-child .image-container {
  margin-right: 1em;
}

.slider-credential-scroll-left,
.slider-credential-scroll-right {
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 6rem;
  z-index: 10;
}

.slider-credential-scroll-left {
  left: 1rem;
}

.slider-credential-scroll-right {
  right: 1rem;
}

.slider-content .scroll-image {
  width: 2rem;
  height: 2rem;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
  opacity: 0;
}

.slider-credential-scroll-right .scroll-image {
  transform: rotate(90deg);
}

.slider-credential-scroll-left .scroll-image {
  transform: rotate(-90deg);
}

.slider-content:hover .scroll-image {
  opacity: 0.3;
}

.slider-credential-scroll-left:hover,
.slider-credential-scroll-right:hover {
  cursor: pointer;
}

.slider-credential-scroll-left:hover .scroll-image,
.slider-credential-scroll-right:hover .scroll-image {
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 900px) {
  .credential-details-moreinfo img {
    width: 70%;
  }
  .slider-content-child {
    width: 10em;
    height: 10em;
  }
  .slider-content-scrollable p {
    width: 10em;
    line-height: normal;
    white-space: break-spaces;
    text-align: center;
  }
}

@media (max-width: 600px) {
  .slider-content-child {
    width: 8em;
    height: 8em;
  }
  .slider-content-scrollable p {
    width: 8em;
  }
}

@media (pointer: none), (pointer: coarse) {
  .slider-credential-scroll-left,
  .slider-credential-scroll-right {
    display: none;
  }
}

@media (max-width: 600px) {
  .credential-details-moreinfo.slider-info,
  .credential-details-moreinfo {
    margin: 0.6em auto;
  }
  .credential-full-details .slider-content {
    height: 15em;
  }

  .credential-details-moreinfo {
    flex-direction: column; /* Change to column layout */
  }
  .moreinfo-block {
    flex: 1;
  }
  .moreinfo-block.services,
  .moreinfo-block.references {
    width: 100%;
  }
  .credential-details-moreinfo .reference-block {
    width: 80%;
  }

  .credential-details-moreinfo .moreinfo-block-special {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 2em;
  }
}
