#home-credentials-container {
  height: 45rem;
  width: 100%;
  align-items: stretch;
  display: flex;
}

.home-credential {
  flex: 1;
  background-color: rgb(77, 106, 106);
  border: 1px solid black;
  transition: 0.4s ease-in-out;
  background-position: center;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
}

.overlay {
  display: none;
  background: linear-gradient(rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.9));
  width: 100%;
  height: 100%;
  position: absolute;
}

.hovered .overlay {
  display: block;
}

.home-credential.hovered {
  flex: 5;
}

.credential-details {
  width: 70%;
  visibility: hidden;
  position: absolute;
  z-index: 1;
  bottom: 1rem;
  left: 2rem;
  transition: 0s ease-in;
}

.credential-details.visible {
  visibility: visible;
  display: block;
  transition: 1s ease-in;
}

.home-credential .credential-client {
  font: 100 1.1rem "Outfit";
  margin: 0.2em 0 1rem 0;
  text-transform: uppercase;
}

.home-credential .credential-name {
  font: 900 1.4rem "Outfit";
  line-break: auto;
  white-space: normal;
  margin: 0;
}

.home-credential {
  height: 100%;
  display: inline-block;
  width: 20%;
}

.home-credential.hovered {
  width: 40%;
}
#home-credentials-container {
  height: 36rem;
  display: block;
  width: 100%;
  overflow-x: hidden;
  white-space: nowrap;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  position: relative;
}

#home-credentials-container::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

#home-credentials-scrollable {
  position: absolute;
  width: 100%;
  height: 100%;

  overflow-x: scroll;
  white-space: nowrap;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  position: relative;
  scroll-behavior: smooth;
}

#home-credential-scroll-left,
#home-credential-scroll-right {
  width: 4rem;
  height: 4rem;
  position: absolute;
  top: 16rem;
  z-index: 10;
}

#home-credential-scroll-left {
  left: 1rem;
}

#home-credential-scroll-right {
  right: 1rem;
}

#home-credentials-container .scroll-image {
  width: 4rem;
  height: 4rem;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
  opacity: 0;
}

#home-credential-scroll-right .scroll-image {
  transform: rotate(90deg);
}

#home-credential-scroll-left .scroll-image {
  transform: rotate(-90deg);
}

#home-credentials-container:hover .scroll-image {
  opacity: 0.3;
}

#home-credential-scroll-left:hover,
#home-credential-scroll-right:hover {
  cursor: pointer;
}

#home-credential-scroll-left:hover .scroll-image,
#home-credential-scroll-right:hover .scroll-image {
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 3000px) {
}

@media (max-width: 900px) {
  .home-credential {
    height: 100%;
    display: inline-block;
    width: 42%;
  }

  .home-credential.hovered {
    width: 90%;
  }
}

@media (max-width: 600px) {
  #home-credentials-container {
    height: 30rem;
    display: block;
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
  }
}

@media (pointer: none), (pointer: coarse) {
  #home-credential-scroll-left,
  #home-credential-scroll-right {
    display: none;
  }
}
