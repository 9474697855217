.horizontal-divider {
  width: 2px;
  height: 14px;
  background-color: white;
}

.horizontal-divider.inverted {
  background-color: black;
  width: 1px;
}
