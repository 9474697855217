#loading-page {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

#loading-page .loading-logo {
  position: relative;
  cursor: pointer;
  width: 50%;
  transition: opacity 0.5s;
}

#loading-page .loading-logo.hidden {
  opacity: 0;
}

#loading-page .loading-logo.show {
  opacity: 1;
}
