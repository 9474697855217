#home-player-wrapper {
  position: relative;
}

#home-player-wrapper:after {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  height: 30%;
  bottom: 0px;
  background: linear-gradient(
    180deg,
    rgb(0 0 0 / 0%) 0%,
    rgb(0 0 0 / 80%) 100%
  );
  pointer-events: none;
}

#home-video-text {
  position: absolute;
  height: 100%;
  margin: 0;
  top: 0%;
  width: 100%;
  display: flex;
  justify-content: center;
}

#home-player-wrapper h1 {
  color: white;
  text-align: center;
  white-space: pre-line;
  margin: 0;
  font-size: 3.8rem;
  font-family: "Outfit";
  letter-spacing: 1.2rem;
  font-weight: 500;
  text-transform: uppercase;
  transition: all 0.5s;
  display: flex;
  align-self: center;
}

#home-player-wrapper h1.visible {
  opacity: 1;
}

#home-player-wrapper h1.invisible {
  opacity: 0;
}

#home-player-wrapper h1 b {
  font-weight: 900;
}

.player-wrapper {
  height: 700px;
}

.player-wrapper video {
  object-fit: cover;
}

@media (max-width: 600px) {
  .player-wrapper {
    height: 400px;
  }
  #home-player-wrapper h1 {
    font-size: 1.8rem;
    letter-spacing: 0.8rem;
    top: 50%;
  }
  #home-player-wrapper:after {
    background: none;
  }
}
