.text-block {
  display: flex;
  gap: 2rem;
}

.text-block-column {
  flex: 1;
}

.text-block.block .text-block-column {
  padding: 0 1rem;
}

.text-block.block .text-block-column:first-child {
  padding-left: 0;
}
.text-block.block .text-block-column:last-child {
  padding-right: 0;
}

.text-block-content {
  margin: 0 0 1rem 0;
  font: 400 1rem "Tinos";
  line-height: 1.4rem;
}

@media (max-width: 900px) {
  .text-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0;
  }
  .text-block.block .text-block-column {
    padding: 0;
  }
}
