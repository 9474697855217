#contact-page-top {
  height: 22em;
}

#contact-page-top img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

#contact-page .section-title {
  margin-top: 5rem;
  margin-bottom: 2rem;
}

#contact-form-wrapper {
  margin-top: 4em;
}

#contact-form-wrapper .contact-form {
  flex: 1;
}

#contact-form-wrapper #empty {
  flex: 1;
}

#contact-form-wrapper .form-legal {
  font: 100 1em "Outfit";
  margin: 1.4em 0 1em 0;
  color: white;
}
#contact-form-wrapper .form-legal a {
  font: 100 1em "Outfit";
  color: white;
  text-decoration: none;
}

#contact-form-wrapper .form-legal a:hover {
  text-decoration: underline;
}

@media (max-width: 900px) {
  #contact-page .section-title {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }

  #contact-form-wrapper .form-legal {
    margin-bottom: 2em;
    margin-top: 1em;
  }
}
