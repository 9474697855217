.selected-service-details {
  display: flex;
}

.selected-service-video {
  flex: 4;
  align-self: start;
  margin-top: 0.6rem;
}

.selected-service-description {
  flex: 6;
}

.selected-service-text {
  margin-bottom: 2em;
}

.tag-group {
  display: flex;
}

.tag-group .tag {
  flex: 1;
  text-align: center;
  border: 1px solid white;
  padding: 0.4em 0.4em;
  margin-bottom: 1em;
  font: 100 0.85rem "Outfit";
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tag-group .tag.spacer {
  margin-left: 1em;
}

.tag-group .empt-tag {
  flex: 0.25;
}

.vertical-tags {
  display: none;
}

@media (max-width: 900px) {
  .selected-service-details {
    flex-direction: column;
  }
  .selected-service-details .selected-service-description {
    margin-top: 0.8rem;
  }
  .selected-service-details .selected-service-description .block {
    padding: 0;
  }

  .tag-group {
    flex-direction: column;
  }
  .tag-group .tag {
    font: 400 0.95rem "Outfit";
  }

  .tag-group .tag.spacer {
    margin-left: 0em;
  }
  .selected-service-video {
    margin-top: 0rem;
  }
}

@media (min-width: 901px) and (orientation: portrait) {
  .horizontal-tags {
    display: none;
  }
  .vertical-tags {
    margin-top: 4rem;
    display: block;
  }

  .selected-service-video {
    margin-top: 0rem;
  }
}
