.clients-container {
  display: flex;
  width: 100%;
  height: 8em;
  position: relative;
  overflow: hidden;
  margin-top: 32px;
  margin-bottom: 32px;
}

.clients-list {
  display: flex;
  justify-content: space-around;
  height: 8em;
  position: absolute;
  overflow: hidden;
  white-space: nowrap;
  gap: 2em;
  animation: scroll-horizontal 35s linear infinite;
}

.client {
  height: 8em;
  aspect-ratio: 3 / 2;
}

.client img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@keyframes scroll-horizontal {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}

@media (max-width: 600px) {
  .client {
    height: 5em;
    aspect-ratio: 3 / 2;
  }

  .clients-list {
    height: 5em;
  }
}
