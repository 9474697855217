.vertical-credential {
  height: 30em;
  position: relative;
  align-items: center;
  transition: all 1s ease;
}

.vertical-credential.selected {
  height: auto;
}

.image-block {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.vertical-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

.horizontal-overlay {
  /*  display: none;*/
  position: relative;
  height: 30em;
  width: 100%;
  background: linear-gradient(
    to right,
    rgb(0 0 0 / 40%) 00%,
    rgb(0 0 0 / 3%) 40%
  );
  z-index: 1;
  position: absolute;
}

.vertical-credential .details {
  /*  display: none; */
  display: flex;
  position: absolute;
  transition: all 0.3s ease;
  z-index: 2;
  left: 6rem;
  bottom: 0;
  height: 300px;
  width: 55%;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.vertical-credential .details h2,
.vertical-credential .details p {
  margin: 0;
}

.vertical-credential .details .button {
  margin-top: 0.4em;
}

.credential-name {
  font: 900 1.8em "Outfit";
  margin: 0;
}

.credential-client {
  font: 100 1.5rem "Outfit";
  margin: 0.6rem 0 1rem 0;
  text-transform: uppercase;
}

.credential-description {
  margin: 0 0 1rem 0;
  font: 400 1.2rem "Tinos";
  line-height: 1.8rem;
}

@media (max-width: 1100px) {
  .vertical-credential .details {
    width: 60%;
  }
}

@media (max-width: 600px) {
  .vertical-credential .details {
    width: 80%;
    left: 3rem;
  }
}

/* Only iOS Devices (iPhone & iPad) */
@supports (-webkit-touch-callout: none) and (not (hover: hover)) {
  .image-block {
    background-attachment: scroll;
  }
}
