#view-all-projects {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

#home-page .section-title {
  margin-top: 8rem;
  margin-bottom: 2rem;
}

@media (max-width: 600px) {
  #home-page .section-title {
    margin-top: 4rem;
  }
}
