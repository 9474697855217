/*
rgb(129, 11, 11) 0%,
    rgb(7, 122, 7) 20%,
    rgb(190, 190, 0) 40%,
    rgb(0, 0, 161) 60%,
    rgb(174, 113, 0) 80%,
    rgb(129, 11, 11) 100%
    */

.animated-gradient {
  background: repeating-linear-gradient(
    to right,
    #003c5d 0%,
    #95698b 5%,
    #d12435 13%,
    #fc561e 26%,
    #fbdc39 39%,
    #abd32d 52%,
    #3aa080 65%,
    #1471e3 78%,
    #003c5d 95%,
    #95698b 1000%
  );
  width: 100%;
  height: 0.4rem;
  background-size: 200% auto;
  background-position: 0 100%;
  animation: gradient 8s infinite;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

@keyframes gradient {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -200% 0;
  }
}
