/*** Service Selector - start ***/
#service-selector {
  position: relative;
  height: 80px;
}

.color-line {
  height: 2px;
  position: absolute;
  width: 100%;
  bottom: 20px;

  background: linear-gradient(
    to right,
    #003c5d 0%,
    #1471e3 13%,
    #3aa080 26%,
    #abd32d 39%,
    #fbdc39 52%,
    #fc561e 65%,
    #d12435 78%,
    #95698b 100%
  );
}

.service-options {
  position: absolute;
  bottom: 0px;
  width: 120px;
  text-align: center;
}

.service-options.left {
  left: 0;
}

.service-options.middle {
  padding: 0;
  margin: auto;
  left: 50%;
  transform: translateX(-50%);
}

.service-options.right {
  right: 0;
}

.service-options p {
  font-family: "Gabarito";
  font-weight: 500;
  font-size: 1.3rem;
  transition: all 0.3s ease;
}

.service-options:hover p {
  text-shadow: #fc0 1px 0 10px;
}

.service-options img {
  width: 60px;
  animation: glow-effect 3s linear infinite;
}

.service-options.left img {
  animation-delay: 0s;
}

.service-options.middle img {
  animation-delay: 1s;
}

.service-options.right img {
  animation-delay: 2s;
}

@keyframes glow-effect {
  0% {
    filter: drop-shadow(1px 1px 7px rgba(255, 255, 255, 1));
  }
  50% {
    filter: none;
  }
  100% {
    filter: drop-shadow(1px 1px 7px rgba(255, 255, 255, 1));
  }
}

#home-services .logo-container {
  margin-top: 2em;
  text-align: center;
}

#home-services .logo-animated {
  height: 6em;
}

/*** Service Selector - end ***/

/*** Service Info - start ***/
.service-info {
  margin-top: 3rem;
  transition: all 0.3s ease;
}

.service-info h3 {
  margin: 0;
  font: 700 italic 2rem "Tinos";
}

.service-info p {
  margin: 1rem 0 0 0;
  font: 400 1.3rem "Tinos";
  line-height: 1.7rem;
}

/*** Service Info - end ***/

@media (max-width: 600px) {
  #service-selector .service-info p {
    width: 200px;
  }
}
