#footer {
  margin-top: auto;
  background-color: white;
}

#footer_info {
  margin: 0 auto;
  padding: 1.7rem 2rem 1.4rem 2rem;
  color: black;
  display: flex;
  flex-direction: row;
}

#footer_info.footer_alt {
  padding: 1rem 3.2rem 1rem 3.2rem;
}

#footer_info .empty {
  flex: 0.5;
}

#footer_info #footer_legal p {
  margin: 0;
}

#footer_contact {
  flex: 4;
  justify-items: start;
  max-width: 50rem;
}

#footer_legal {
  flex: 1;
}
#footer_legal .links {
  display: inline-block;
}

#footer_legal .legal_title {
  margin: 0;
  margin-top: 0rem;
  margin-bottom: 0.55rem;
  font: bold italic 1.2rem "Tinos";
}
#footer_legal a {
  margin: 0.3rem 0;
  display: block;
  text-transform: uppercase;
  text-decoration: none;
  color: black;
  font: 100 normal 0.9rem "Outfit";
}

#footer_legal a:hover {
  color: #666666;
}

#footer_copyright a {
  color: white;
  text-decoration: none;
}
#footer_copyright a:hover {
  text-decoration: underline;
}

#footer_copyright .link_dividir {
  padding: 0 0.4rem;
  text-align: center;
}

#footer_details {
  flex: 1;
  text-align: end;
  justify-items: end;
  display: grid;
  grid-gap: 4px;
}

#footer_details img.logo {
  height: 5rem;
  margin-bottom: 0.7rem;
}

.footer_details_social {
  text-align: center;
  display: block;
  width: 9.9rem;
}

.footer_details_social a {
  display: inline-block;
}

.footer_details_social img {
  height: 1.5em;
  margin: 0 0.5em;
}

#footer_copyright {
  padding: 0.5rem 1.2rem;
  text-align: center;
  color: white;
  background-color: #666666;
  font: bold italic 0.83rem "Tinos";
}

#footer_copyright p {
  margin: 0;
  padding: 0;
}

.mobile-divider {
  display: none;
}

.footer_alt #footer_details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#footer_legal {
  display: none;
}

#footer_copyright p {
  display: inline;
}
#footer_copyright .extra {
  display: inline;
}

@media (max-width: 900px) {
  #footer_legal {
    display: block;
  }

  .footer_alt #footer_details {
    flex-direction: column;
  }

  #footer_info {
    display: flex;
    flex-direction: column;
    padding: 2rem 1.5em;
  }

  #footer_details {
    order: 1;
    justify-items: center;
    text-align: center;
  }
  #footer_details .footer_details_social {
    margin: 0 auto;
    display: block;
  }

  #footer_contact {
    order: 2;
    justify-items: center;
    margin-top: 2em;
  }

  #footer_legal {
    order: 3;
  }

  #footer_info .empty {
    flex: 0;
    display: none;
  }

  #footer_legal {
    text-align: center;
    margin-top: 2em;
    margin-bottom: 0%;
  }

  #footer_legal .legal_title {
    margin-bottom: 0.2em;
  }

  #footer_legal .links {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4em;
  }

  #footer_copyright {
    padding: 0.6em 1em 0.6em 1em;
    margin: 0;
  }
  #footer_copyright p {
    display: block;
  }
  #footer_copyright .extra {
    display: none;
  }

  .mobile-divider {
    display: inline-block;
  }
}

/*
@media (min-width: 1600px) {
  #footer_info {
    width: 1600px;
  }
}
  */

@media (max-width: 1200px) {
  #footer_details img.logo {
    height: 4rem;
    margin-bottom: 1em;
  }
  #footer_copyright {
    margin: 0;
  }
}

@media (max-width: 1159px) {
  .empty {
    flex: 0.1;
  }
}

@media (max-width: 800px) {
  .footer_details_social img {
    height: 2rem;
    margin: 0 0.5rem;
  }
}

@media (min-width: 2000px) {
  #footer_info {
    padding: 1.7rem 5rem 1.4rem 5rem;
  }
}
