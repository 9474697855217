.section-title {
  text-align: center;
}

.section-title h3 {
  margin: 0;
  margin-bottom: 0.1rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font: 900 0.9rem "Outfit";
}

.section-title h2 {
  margin: 0;
  font: 300 1.7rem "Outfit";
}

.section-title.small h2 {
  font-size: 1.6rem;
}

@media (max-width: 1000px) {
  .section-title h2 {
    font-size: 1.4rem;
  }

  .section-title h3 {
    font-size: 1rem;
  }

  .section-title.small h2 {
    font-size: 1rem;
  }
}
