html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.block {
  padding-left: 5rem;
  padding-right: 5rem;
}

.page-top-margin {
  margin-top: 3em;
}

.page {
  background-color: black;
  margin-bottom: 8rem;
}

.lock-scroll {
  overflow: hidden;
  margin-right: 14px;
}

.lock-scroll nav {
  padding-right: 14px;
}

.legal-under {
  margin-top: 3em;
  text-decoration: underline;
}

.legal-bold {
  font-weight: bold;
}

/* WOFF -> Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
/* TTF -> Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
@font-face {
  font-family: "Gabarito";
  font-weight: 500; /* medium */
  src: url("./assets/fonts/gabarito/Gabarito-Medium.ttf") format("woff"),
    url("./assets/fonts/gabarito/Gabarito-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Outfit";
  font-weight: 100; /* thin */
  src: url("./assets/fonts/outfit/Outfit-Thin.woff") format("woff"),
    url("./assets/fonts/outfit/Outfit-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Outfit";
  font-weight: 300; /* light */
  src: url("./assets/fonts/outfit/Outfit-Light.woff") format("woff"),
    url("./assets/fonts/outfit/Outfit-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Outfit";
  font-weight: 900; /* black */
  src: url("./assets/fonts/outfit/Outfit-Black.woff") format("woff"),
    url("./assets/fonts/outfit/Outfit-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Tinos";
  font-weight: 400; /* regular */
  font-style: normal;
  src: url("./assets/fonts/tinos/Tinos-Regular.woff") format("woff"),
    url("./assets/fonts/tinos/Tinos-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Tinos";
  font-weight: 700; /* bold */
  font-style: italic;
  src: url("./assets/fonts/tinos/Tinos-BoldItalic.woff") format("woff"),
    url("./assets/fonts/tinos/Tinos-BoldItalic.ttf") format("truetype");
}

.container {
  margin: 0 auto;

  padding-left: 4rem;
  padding-right: 4rem;
}

@media (max-width: 600px) {
  .container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .page {
    margin: 0;
  }
  .page.page-top-margin,
  .page-top-margin {
    margin-top: 2em;
  }

  html {
    font-size: 14px;
  }
  .block {
    padding: 0;
  }
}
@media (max-width: 1000px) {
  .page-top-margin {
    margin-top: 2em;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

@media (min-width: 2000px) {
  .container {
    width: 1500px;
  }
}
